import { navigate } from 'gatsby'
import { ProductCart, AlgoliaProduct, getProductsBySkus, getProducts, AlgoliaAttributes } from '@ecommerce/shared'
import secrets from '../config/secrets'
import { sendSelectItemEvent } from './events'
import { generateProductUrl } from '../config/siteBuild/utils'

const { COUNTRY } = secrets

export const getNavigatableProductsBySkus = async (
  slugLocation: string,
  skus: string[],
  attributes?: AlgoliaAttributes,
  citySlug?: string,
  productListName?: string,
  navigationState?: Record<string, any>,
  isGift?: boolean,
): Promise<ProductCart[]> =>
  getProductsBySkus({ slugLocation, skus, attributes }).then((products) => {
    const productsFiltered = isGift ? products : products?.filter((product) => product.isDummy !== true)
    return productsFiltered.map((product) => {
      const formattedProduct = {
        ...product,
        categoryName: product.categoryName,
        brandName: product.brandName,
        quantity: 1,
        discount: product.discount ?? 0,
        stock: product.stock,
      }
      return {
        ...formattedProduct,
        onNavigate: () => {
          sendSelectItemEvent(formattedProduct, productListName || '', COUNTRY === 'BO')
          navigate(`/products/${generateProductUrl(product.title, product.skuCode)}`, {
            state: { originListName: productListName, ...(navigationState || {}) },
          })
        },
      }
    })
  })

export const getProductsBySlugLocation = async (slugLocation: string, skus: string[]): Promise<ProductCart[]> => {
  const filters = `slugLocation:${slugLocation} AND (sku=${skus.join(' OR sku=')})`
  const products = (await getProducts(filters)) as AlgoliaProduct[]

  return products.map((product) => ({ ...product, quantity: 1, discount: 0 }))
}

export const formatFilterByCategoryNames = (categoryNames: string[]) => {
  if (categoryNames && Array.isArray(categoryNames) && categoryNames.length > 1) {
    const string = categoryNames.map((cat) => `"${cat}"`).join(` OR mainCategoryName:`)
    return `(mainCategoryName:${string})`
  }

  return `mainCategoryName:"${categoryNames[0]}"`
}

export const formatFilterBySkus = (skusQuery: string | null) => {
  if (!skusQuery) return ''
  const skus = skusQuery.split(',')
  return `AND (${skus.map((sku) => `sku=${sku}`).join(' OR ')})`
}
