import React, { useState, useEffect, useMemo, memo } from 'react'
import styled from 'styled-components'
import { log, ProductCart as ProductCartType, useCart } from '@ecommerce/shared'
import { getNavigatableProductsBySkus } from '../../utils/algolia'
import ProductCartSkeleton from './ProductCartSkeleton'
import ProductCart from './ProductCart'

type Props = {
  items?: Array<{
    sku: number
    quantity: number
  }>
  slugLocation: string
  loading?: boolean
  updatingOrder?: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
`

const GiftProduct = memo(({ items, slugLocation, loading, updatingOrder = false }: Props) => {
  const [products, setProducts] = useState<ProductCartType[]>()
  const [isLoadingProducts, setIsLoadingProducts] = useState(loading)
  const { setSkusToInject } = useCart()

  const skus = useMemo(() => items?.map((item) => item.sku.toString()), [items])

  const getDiscountItem = (sku: number) => {
    return items?.find((item) => item.sku === sku)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (skus) {
          setIsLoadingProducts(true)
          const algoliaProducts = await getNavigatableProductsBySkus(
            slugLocation,
            skus,
            {},
            slugLocation,
            undefined,
            undefined,
            true,
          )

          setProducts(algoliaProducts)
        }
      } catch (error) {
        log.error(error)
      } finally {
        setIsLoadingProducts(false)
      }
    }

    if (skus?.length && !loading) fetchData()
  }, [])

  useEffect(() => {
    if (!loading) {
      if (products?.length && items && items.length > 0) {
        const transformedProducts = products.map<ProductCartType>((product) => {
          const discountItem = getDiscountItem(Number(product.skuCode))
          return {
            ...product,
            quantity: discountItem?.quantity || 0,
            isGift: true,
            discountQuantity: discountItem?.quantity || 0,
            discount: product?.discount || 0,
          }
        })

        setSkusToInject(transformedProducts)
      } else {
        setSkusToInject([])
      }
    }
  }, [products, setSkusToInject, loading, items])

  if (items?.length === 0) return null
  return (
    <Wrapper>
      {isLoadingProducts || loading || !products?.length ? (
        <>
          {Array(items?.length)
            .fill(0)
            .map((_, i) => (
              <ProductCartSkeleton key={i} />
            ))}
        </>
      ) : (
        <>
          {products.map((product) => (
            <ProductCart
              isGift
              giftQuantity={getDiscountItem(Number(product?.skuCode))?.quantity || 0}
              key={product.skuCode}
              product={product}
              updatingOrder={updatingOrder}
            />
          ))}
        </>
      )}
    </Wrapper>
  )
})

export default GiftProduct
