import { useState } from 'react'
import { AuthFormType } from '@ecommerce/shared'

type State = {
  showWidget: boolean
  type?: AuthFormType
}

const initialState: State = { showWidget: false }

const useAuthWidget = () => {
  const [state, setState] = useState<State>(initialState)
  const [noClose, setNoClose] = useState(false)

  const showWidget = (type: AuthFormType) => setState({ showWidget: true, type })
  const closeWidget = () => setState(initialState)

  const getProps = (formType: AuthFormType, props: Record<AuthFormType, any>) => ({
    type: formType,
    props: props[formType],
  })

  return { showWidget, closeWidget, setNoClose, noClose, state, getProps }
}

export default useAuthWidget
