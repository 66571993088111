import styled from 'styled-components'
import React from 'react'
import { Icon, IconId, StyleButtonProps } from '@ecommerce/chile-customer-webapp/src/components/Icon/Icon'
import { hexToRGBA } from '../../../utils/format'

const ButtonIcon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
  border: 1px solid ${({ theme }) => hexToRGBA(theme.colors.black20, 0.1)};
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.grey : 'transparent')};
  box-shadow: 1px 1px 1px 0 rgba(209, 209, 209, 0.5);
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  .icon {
    fill: ${({ disabled, theme }) => (disabled ? theme.colors.green : 'auto')};
  }
`

interface IconButtonProps extends StyleButtonProps {
  className?: string
  disabled?: boolean
  iconId: IconId
  onClick: () => void
}

export const IconButton = ({ className, disabled, iconId, onClick, ...rest }: IconButtonProps) => {
  return (
    <ButtonIcon onClick={onClick} disabled={disabled} className={className}>
      <Icon iconId={iconId} className="icon" {...rest} />
    </ButtonIcon>
  )
}
