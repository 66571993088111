import React, { useState, useMemo, useEffect } from 'react'
import loadable from '@loadable/component'
import {
  Image,
  useShoppingCart,
  toNumber,
  ModalBackground,
  useLocation,
  useDisableAddProductButton,
  PromotionDetail,
  showToast,
  ProductCart as ProductCartType,
  Loading,
  PromotionType,
  getOrderId,
  getReturnabilityType,
  ReturnabilityType,
} from '@ecommerce/shared'
import { IconButton } from '@ecommerce/shared/src/components/atoms/IconButton'
import DotsLoader from '../DotsLoader'
import { IconTrash, IconInfo, RetornabilityIcon } from '../Icons'
import secrets from '../../config/secrets'
import ConfirmationAlert, { ConfirmationAlertButtonsDirection } from '../ConfirmationAlert'
import { AlertModalSize } from '../Alert'
import { Icon } from '../Icon/Icon'
import { sendRemoveProductEvent } from '../../utils/events'
import {
  ButtonTrash,
  DescriptionCart,
  DetailCart,
  ImageCart,
  PriceCart,
  ProductInfo,
  RetornabilityContent,
  ReturnabilityTag,
  ReturnabilityWrapper,
  Wrapper,
  WrapperDetails,
  WrapperLoading,
} from './ProductCart.styled'

const ConfirmModal = loadable(() => import('./ConfirmModal'))

const minSubtotal = Number(secrets.MIN_RAW_TOTAL)
const skusLimited: Array<number> = secrets.SKU_LIST_LIMITED_SALE.split(',').map((sku) => Number(sku))

interface Props {
  product: ProductCartType
  presale?: boolean
  promotion?: PromotionDetail
  setLoadingPrice?: (b: boolean) => void
  loadingPrice?: boolean
  isGift?: boolean
  giftQuantity?: number
  isCheckout?: boolean
  lineItemId?: string
  onRemoveProduct?: ({ skuCode, lineItemId }: ProductCartType, orderId: string) => Promise<void>
  setCartLoading?: (state: boolean) => void
  orderTotal?: number
  giftsLineItemsRelated?: any
  subTotal?: number
  onUpdateCartState?: () => void
  disableButtons?: boolean
  updatingOrder?: boolean
  returnabilityLabel?: string
}

const ProductCart = (props: Props) => {
  const {
    product,
    presale,
    promotion,
    isGift,
    giftQuantity,
    isCheckout = false,
    onRemoveProduct,
    lineItemId = null,
    setCartLoading = null,
    orderTotal = 0,
    giftsLineItemsRelated = [],
    onUpdateCartState,
    disableButtons = false,
    updatingOrder = false,
    returnabilityLabel,
  } = props

  const { skuCode, image, title, discount = 0, price, rawPrice = 0, recyclable = false, quantity } = product

  const { removeProduct, state, updateProduct } = useShoppingCart()

  const promotionData = promotion?.promotion?.type !== PromotionType.A_DISCOUNTS_B ? promotion : undefined

  const discountAmount = promotionData?.discountAmount ?? 0

  const [firestoreUpdateTimeout, setFirestoreUpdateTimeout] = useState(0)
  const [productQuantity, setProductQuantity] = useState(quantity)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenModalMinimumAmount, setIsOpenModalMinimumAmount] = useState(false)
  const [updatingOrderProduct, setUpdatingOrderProduct] = useState(updatingOrder)

  const [typeRemoveProduct, setTypeRemoveProduct] = useState<null | 'decrease' | 'remove'>(null)
  const [typeRemoveProductConfirmation, setTypeRemoveProductConfirmation] = useState<null | 'decrease' | 'remove'>(null)

  const [loadingPrice, setLoadingPrice] = useState(false)

  const orderId = getOrderId()

  const { toCurrency, textByCountry, isBolivia } = useLocation()

  const returnabilityType: ReturnabilityType = useMemo(() => {
    return getReturnabilityType({ returnabilityLabel })
  }, [returnabilityLabel])

  const disabledAddButtonLimitedProducts = () => {
    return skusLimited.includes(Number(product.skuCode)) && productQuantity >= 3
  }

  useEffect(() => {
    if (!updatingOrder) {
      setUpdatingOrderProduct(updatingOrder)
    }
  }, [updatingOrder])

  useEffect(() => {
    if (setLoadingPrice) setLoadingPrice(false)
  }, [state.promotionsUpdatedAt])

  const resetFirebaseUpdateTimeout = () => {
    clearTimeout(firestoreUpdateTimeout)
    setFirestoreUpdateTimeout(
      setTimeout(async () => {
        await updateProduct(product, productQuantity, orderId, onUpdateCartState)
      }, 500),
    )
  }

  useEffect(() => {
    if (loadingPrice) {
      setUpdatingOrderProduct(true)
      resetFirebaseUpdateTimeout()
    }
  }, [productQuantity, loadingPrice])

  useEffect(() => {
    setLoadingPrice(false)
  }, [state.promotionsUpdatedAt])

  const onIncrease = () => {
    if (setLoadingPrice) {
      setLoadingPrice(true)
    }
    if (disabledAddButtonLimitedProducts()) {
      showToast({
        content: `Puedes llevar un máximo de 3 unidades de ${title} por compra.`,
        type: 'info',
      })
      return
    }
    setProductQuantity(productQuantity + 1)
  }

  const onDecrease = () => {
    if (setLoadingPrice) setLoadingPrice(true)
    setProductQuantity(productQuantity - 1)
  }

  const removeOrderLineItem = async (sku: string, lineItem: string) => {
    if (!sku || !lineItem) {
      return
    }

    if (onRemoveProduct) {
      await onRemoveProduct({ skuCode: sku, lineItemId: lineItem } as ProductCartType, orderId ?? '')
    }
  }

  async function onDeleteAll() {
    if (setCartLoading) {
      setCartLoading(true)
    }
    sendRemoveProductEvent([product], isBolivia())
    if (lineItemId) {
      await removeOrderLineItem(skuCode, lineItemId)
      if (setCartLoading) {
        setCartLoading(false)
      }
      return
    }
    await removeProduct(product, orderId)
    if (setCartLoading) {
      setCartLoading(false)
    }
  }

  const getGiftLabel = () => {
    if (isBolivia()) {
      return <Icon iconId="gift" strokeColor="green" size="18" />
    }

    return <span className="product-cart-gift-label">Regalo</span>
  }

  const onDecreaseCheckPromotion = () => {
    // Discount promotions
    if (isCheckout && promotionData && promotionData.promotion) {
      const { activationQuantity = null, minActivationAmount = null } = promotionData.promotion

      if (
        (activationQuantity && productQuantity - 1 < activationQuantity) ||
        (minActivationAmount && orderTotal < minActivationAmount)
      ) {
        return setTypeRemoveProductConfirmation(productQuantity === 1 ? 'remove' : 'decrease')
      }
    }

    // Gift related promotions
    if (isCheckout && giftsLineItemsRelated.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const gift of giftsLineItemsRelated) {
        const { activationQuantity, minActivationAmount } = gift.promotion

        if (
          (activationQuantity && productQuantity - 1 < activationQuantity) ||
          (minActivationAmount && orderTotal < minActivationAmount)
        ) {
          return setTypeRemoveProductConfirmation(productQuantity === 1 ? 'remove' : 'decrease')
        }
      }
    }

    if (productQuantity === 1) {
      onDeleteAll()
    } else {
      onDecrease()
    }
  }

  const disabledAddButton = useDisableAddProductButton({ skuCode: product.skuCode, quantity: productQuantity })
  const isDisabled = !!product.labelUrl || presale || disabledAddButton || isGift || disableButtons

  const returnabilityLabelText = useMemo(() => {
    if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE) {
      return `Incluye ${textByCountry('envase', 'botella')}`
    }
    if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE_PLUS_BOX) {
      return `Incluye ${textByCountry('envase', 'botella')} y jaba`
    }

    return `Retorna tu ${textByCountry('envase', 'botella')}`
  }, [returnabilityType])

  const infoBadgeText = useMemo(() => {
    if (presale) return `Preventa (Entrega el 03/12)`
    if (typeof product.labelUrl === 'string') return `Producto personalizado`

    return null
  }, [recyclable, product?.presale, product?.labelUrl])

  return (
    <>
      <Wrapper isCheckout={!!isCheckout}>
        {isOpenModal && (
          <ModalBackground>
            <ConfirmModal
              closeModal={() => setIsOpenModal(false)}
              onDeleteAll={onDeleteAll}
              image={image}
              message={title}
              centerModal={!!isCheckout}
            />
          </ModalBackground>
        )}
        <WrapperDetails>
          <ReturnabilityWrapper>
            {returnabilityType === ReturnabilityType.RETURNABLE && (
              <ReturnabilityTag returnability={returnabilityType}>
                <RetornabilityContent>
                  <RetornabilityIcon />
                  {returnabilityLabelText}
                </RetornabilityContent>

                <a
                  href={textByCountry(
                    'https://embonor.micoca-cola.cl/momento/sustentable',
                    'https://www.micoca-cola.bo/momento/sustentable ',
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  Más info
                </a>
              </ReturnabilityTag>
            )}
          </ReturnabilityWrapper>
          <DetailCart
            key={skuCode}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
            showReturnableTag={returnabilityType === ReturnabilityType.RETURNABLE}
          >
            {image && (
              <ImageCart>
                <Image preload={false} src={image} alt="detail-cart" />
              </ImageCart>
            )}

            <DescriptionCart recycled={recyclable} disabled={disabledAddButtonLimitedProducts()}>
              <div className="product-cart-title">
                <span className="product-cart-title-name">{title}</span>
              </div>

              {discount && discount > 0 ? (
                <span className="product-cart-title-percentDcto">{`${toNumber(discount)}% Dcto.`}</span>
              ) : (
                ''
              )}

              {promotionData && promotionData.labels ? (
                <span className="product-cart-title-promotion-label">{promotionData.labels[0]}</span>
              ) : (
                ''
              )}

              <div className="product-cart-button">
                <IconButton
                  disabled={isGift || disableButtons}
                  data-test="product-cart-decrease"
                  iconId="decrease-cart"
                  className="product-cart-button-decrease-cart"
                  size={12}
                  onClick={() => {
                    if (isGift || disableButtons) return null
                    if (isCheckout && orderTotal - product.price < minSubtotal) {
                      setTypeRemoveProduct('decrease')
                      return setIsOpenModalMinimumAmount(true)
                    }
                    if (productQuantity === 1) {
                      if (isCheckout) {
                        return onDecreaseCheckPromotion()
                      }
                      return setIsOpenModal(true)
                    }

                    return onDecreaseCheckPromotion()
                  }}
                />
                <span className="product-cart-title-total">{toNumber(giftQuantity || productQuantity)}</span>
                <IconButton
                  data-test="product-cart-increase"
                  iconId="add-cart"
                  size={12}
                  className="product-cart-button-increase-cart"
                  onClick={isDisabled ? () => null : onIncrease}
                  disabled={isDisabled || disabledAddButtonLimitedProducts()}
                />
              </div>
            </DescriptionCart>

            <PriceCart
              className="product-cart-price"
              discount={(discount && discount > 0) || discountAmount > 0}
              isGift={!!isGift}
              isDisabled={!!disableButtons}
            >
              {!isGift ? (
                <>
                  <ButtonTrash
                    data-testid="trash-product"
                    whileTap={disableButtons ? {} : { scale: 0.75 }}
                    onClick={() => {
                      if (disableButtons) return null

                      if (!isCheckout) {
                        return setIsOpenModal(true)
                      }
                      if (
                        orderTotal - product.quantity * product.price < minSubtotal &&
                        !promotionData &&
                        !giftsLineItemsRelated.length
                      ) {
                        setTypeRemoveProduct('remove')
                        return setIsOpenModalMinimumAmount(true)
                      }
                      if (!promotionData && !giftsLineItemsRelated.length) {
                        return onDeleteAll()
                      }

                      setTypeRemoveProductConfirmation('remove')
                    }}
                  >
                    <IconTrash />
                  </ButtonTrash>
                  {(discount && discount > 0) || discountAmount > 0 ? (
                    <span data-testid="product-price" className="product-cart-price-normal">
                      {toCurrency(rawPrice * quantity)}
                    </span>
                  ) : null}
                  {!loadingPrice || !promotionData || lineItemId ? (
                    <span data-testid="product-discount" className="product-cart-price-discount">
                      {toCurrency(Math.ceil(discountAmount ? rawPrice * quantity - discountAmount : price * quantity))}
                    </span>
                  ) : (
                    <div className="product-cart-price-loading">
                      <DotsLoader />
                    </div>
                  )}
                </>
              ) : (
                getGiftLabel()
              )}
            </PriceCart>
          </DetailCart>
          <ProductInfo
            showMessage={!!product.labelUrl || !!presale}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
            presale={product?.presale}
          >
            <button type="button" className="info-button">
              <IconInfo />
            </button>
            <span>{infoBadgeText}</span>
          </ProductInfo>
        </WrapperDetails>
        {isCheckout && (
          <WrapperLoading isLoading={updatingOrderProduct}>
            <Loading />
          </WrapperLoading>
        )}
      </Wrapper>

      {typeRemoveProductConfirmation && (
        <ConfirmationAlert
          onClose={() => setTypeRemoveProductConfirmation(null)}
          onConfirm={() => {
            if (typeRemoveProductConfirmation === 'remove') {
              onDeleteAll()
            } else {
              onDecrease()
            }
            setTypeRemoveProductConfirmation(null)
          }}
          onCancel={() => setTypeRemoveProductConfirmation(null)}
          confirmButtonText="Cancelar"
          cancelButtonText="Confirmar"
          text={
            typeRemoveProductConfirmation === 'remove'
              ? '¿Quieres eliminar esta promoción de tu compra?'
              : 'Recuerda que este producto esta promoción'
          }
          description={
            typeRemoveProductConfirmation === 'remove'
              ? 'Si eliminas este producto no se aplicará la promoción asociada'
              : 'Si quitas unidades de este producto perderás la promoción y no se aplicara el descuento a tu compra.'
          }
          modalSize={AlertModalSize.NORMAL}
          absolutePositionCloseIcon
          buttonsDirection={ConfirmationAlertButtonsDirection.ROW}
          switchButtons
        />
      )}

      {isOpenModalMinimumAmount && (
        <ConfirmationAlert
          onClose={() => {
            setIsOpenModalMinimumAmount(false)
            setTypeRemoveProduct(null)
          }}
          onConfirm={() => {
            setTypeRemoveProduct(null)
            setIsOpenModalMinimumAmount(false)
          }}
          onCancel={() => {
            if (typeRemoveProduct === 'decrease') {
              onDecreaseCheckPromotion()
            }
            if (typeRemoveProduct === 'remove') {
              onDeleteAll()
            }

            setTypeRemoveProduct(null)
            setIsOpenModalMinimumAmount(false)
          }}
          confirmButtonText="OK"
          cancelButtonText="Cerrar"
          text={`Recuerda que el monto mínimo de compra es ${toCurrency(minSubtotal)}`}
          description=" "
          modalSize={AlertModalSize.NORMAL}
          absolutePositionCloseIcon
          buttonsDirection={ConfirmationAlertButtonsDirection.ROW}
          switchButtons
        />
      )}
    </>
  )
}

export default ProductCart
