import React from 'react'
import styled from 'styled-components'
import { toCssPrefix } from '@ecommerce/shared'
import SkeletonItem from '../Skeletons/SkeletonItem'

const { cssPrefix, toPrefixArray } = toCssPrefix('ProductCartSkeleton__')
const Wrapper = styled.div`
  width: 90%;
  height: 114px;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  position: relative;
  padding: 15px 10px 23px;

  .${cssPrefix} {
    &skeleton {
      min-width: unset;
    }

    &image {
      width: 77px;
      min-width: 77px;
      height: 72px;
      margin-right: 8px;
      display: block;
    }

    &title,
    &promotion-label {
      border-radius: 18px;
      height: 11px;
      width: 220px;
    }

    &promotion-label {
      position: absolute;
      left: 95px;
      top: 30px;
    }

    &price {
      width: 50px;
      height: 14px;
      position: absolute;
      right: 27px;
      bottom: 23px;
      border-radius: 24px;
    }
  }
`

const ProductCartSkeleton = () => {
  return (
    <Wrapper className={cssPrefix}>
      <SkeletonItem animate className={toPrefixArray(['skeleton', 'image'])} />
      <SkeletonItem animate className={toPrefixArray(['skeleton', 'title'])} />
      <SkeletonItem animate className={toPrefixArray(['skeleton', 'promotion-label'])} />
      <SkeletonItem animate className={toPrefixArray(['skeleton', 'price'])} />
    </Wrapper>
  )
}

export default ProductCartSkeleton
