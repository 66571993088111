import styled, { css, DefaultTheme } from 'styled-components'
import { motion } from 'framer-motion'
import { breakpoints, ReturnabilityType } from '@ecommerce/shared'

export const Wrapper = styled.div<{ isCheckout: boolean }>`
  ${({ isCheckout }) =>
    isCheckout
      ? css`
          width: 100%;
          position: relative;
        `
      : css`
          width: 100%;
        `};
`

export const WrapperDetails = styled(motion.div)`
  width: 100%;
  height: 120px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 13px;
  box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
`

export const DetailCart = styled(motion.div)<{ showReturnableTag?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-inline: 8px;
  padding-block: ${(props) => (props.showReturnableTag ? '8px' : '16px')};
  @media (${breakpoints.phoneLandscape.min}) {
    width: 100%;
  }
`

export const ImageCart = styled.div`
  width: 82px;
  height: 72px;
  padding-right: 10px;
  img {
    width: 72px;
    height: 72px;
    border-radius: 8px;
  }
`

export const DescriptionCart = styled.div<{ recycled: boolean; disabled: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;
  height: ${(props) => (props.recycled ? 'auto' : '80px')};

  > * {
    display: flex;
    justify-content: flex-start;
    padding: 2px;
  }

  span {
    color: color: ${({ theme }) => theme.colors.black60};;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .product-cart-title {
    display: block;
    font-size: 11px;
    height: 30px;
    overflow: hidden;
    padding: 0;
    &-name {
      white-space: normal;
      line-clamp: 2;
      color: ${({ theme }) => theme.colors.platinum};
    }
    &-name,
    &-description {
      font-size: 12px;
      font-weight: bold;
    }
    &-percentDcto {
      background-color: ${({ theme }) => theme.colors.green};
      color: ${({ theme }) => theme.colors.white};
      font-size: 10px;
      font-weight: normal;
      padding-inline: 4px;
      padding-block: 2px;
      width: fit-content;
      border-radius: 4px;
      margin-top: 2px;
    }
    &-promotion-label {
      color: ${({ theme }) => theme.colors.green};
      font-size: 11px;
      font-weight: normal;
      padding: 0;
      font-weight: bold;
    }
    &-total {
      color: ${({ theme }) => theme.colors.platinum};
      font-size: 16px;
      font-weight: bold;
    }
  }
  .product-cart-button {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    &-decrease-cart,
    &-increase-cart {
      width: 26px;
      height: 26px;
      svg {
        fill: antiquewhite;
      }
    }
    &-icon-add {
      opacity: ${({ disabled }) => (disabled ? '40%' : '100%')};
    }
  }
`

export const PriceCart = styled.div<{ discount: boolean; isGift: boolean; isDisabled: boolean }>`
  width: 27%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: ${(props) => (props.discount ? '80px' : '76px')};

  ${({ isGift, isDisabled }) =>
    !isGift &&
    !isDisabled &&
    css`
      svg {
        cursor: pointer;
        :hover {
          opacity: 0.6;
        }
      }
    `}

  .product-cart-gift-label {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.platinum80};
    display: block;
    position: relative;
    margin-top: auto;
  }

  .product-cart-price {
    &-normal {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.platinum};
      text-decoration: ${(props) => (props.discount ? 'line-through' : 'none')};
    }
    &-discount {
      font-size: 16px;
      color: ${({ theme }) => theme.colors.platinum};
      font-weight: bold;
      height: 19px;
      white-space: nowrap;
    }
    &-loading {
      width: 30px;
      height: 19px;
      display: flex;
      align-items: flex-end;
    }
  }
`

export const ButtonTrash = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.red20};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  outline-color: transparent;
  outline-style: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: 0;
    border: none;
    outline-style: none;
    -moz-outline-style: none;
  }
  svg {
    fill: ${({ theme }) => theme.colors.darkRed};
    object-fit: contain;
    height: 16px;
    width: 16px;
  }
`

const infoBadgeColor = () => ({
  theme,
  ...props
}: {
  isCustomizable?: boolean
  presale?: boolean
  theme: DefaultTheme
}) => {
  if (props.presale) {
    return theme.colors.gold
  }

  if (props.isCustomizable) {
    return theme.colors.blue80
  }

  return 'inherit'
}

export const ProductInfo = styled(motion.div)<{ showMessage: boolean; presale?: boolean }>`
  height: 15px;
  min-width: 170px;
  padding-left: 5px;
  display: ${({ showMessage }) => (showMessage ? 'flex' : 'none')};
  align-items: center;

  span {
    width: auto;
    height: 13px;
    font-size: 11px;
    font-weight: bold;
    color: ${infoBadgeColor()};
    cursor: pointer;
  }

  .info-button {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    margin-right: 3px;
    background: none;
    border: none;
    padding: 0;

    svg {
      width: 15px;
      height: 15px;
      opacity: 0.75;
      fill: ${infoBadgeColor()};
      object-fit: contain;
    }
  }
`

export const WrapperLoading = styled.div<{ isLoading: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;

  ${({ isLoading }) =>
    !isLoading &&
    css`
      display: none;
    `}

  div {
    transform: scale(0.8);
  }
`
export const ReturnabilityWrapper = styled.div`
  width: 100%;
`

export const ReturnabilityTag = styled.div<{ returnability?: string }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-inline: 8px;
  padding-block: 4px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, returnability }) =>
    returnability === ReturnabilityType.RETURNABLE ? theme.colors.returnability : theme.colors.skyBlue};

  @media screen and (${breakpoints.tabletPortrait.min}) {
    font-size: 10px;
    line-height: 18px;
    letter-spacing: -0.06px;
  }
  a {
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
    font-weight: 325;
    line-height: 16px;
  }
`

export const RetornabilityContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
`
