import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { breakpoints, ZIndex } from '@ecommerce/shared'

export const ContainerCart = styled(motion.div)<{ isOpen?: boolean }>`
  display: flex;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: ${ZIndex.medium};
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.grey60};

  .location-selector {
    margin-top: 72px;
    background: ${({ theme }) => theme.colors.white};
    width: 100%;
  }

  .shopping-cart-items {
    padding-block-start: 0;
  }

  .ShoppingCart__confirmation {
    &-alert {
      margin: 0;
      padding-top: 13px;
      &-background {
        position: absolute;
        align-items: center;
      }
    }
  }

  @media (${breakpoints.tabletPortrait.min}) {
    width: 375px;
    .summary {
      width: 375px;
    }
    .summary-container {
      padding-inline: 16px;
      padding-block: 16px;
    }
    .summary-title {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
    .summary-content {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.06px;
    }
    .summary-subtotal {
      font-size: 17px;
      line-height: 25px;
      letter-spacing: -0.24px;
    }
  }
`

export const HeaderClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 72px;
  background-color: ${(props) => props.theme.colors.red};
  box-shadow: ${(props) => props.theme.boxShadow.lvlOne};
  position: fixed;
  z-index: ${ZIndex.medium};
  top: 0;
  padding-inline-start: 16px;

  span {
    display: flex;
    align-items: center;
    height: 25px;
    font-size: 17px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.white};
    svg {
      margin-inline-end: 12px;
      :hover {
        cursor: pointer;
      }
    }
  }

  span > span {
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    letter-spacing: -0.06px;
    margin-inline-start: 8px;
  }

  @media (${breakpoints.tabletPortrait.min}) {
    width: 375px;
  }
`

export const ButtonClose = styled(motion.button)`
  position: fixed;
  right: 15px;
  top: 20px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  width: 34px;
  height: 24px;
  z-index: ${ZIndex.medium};
  -webkit-tap-highlight-color: transparent;

  svg {
    fill: ${(props) => props.theme.colors.white};
  }
`

export const WrapperDetailCart = styled(motion.div)<{ isCheckout?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;

  ${({ isCheckout }) =>
    isCheckout
      ? css`
          max-height: 410px;
          overflow: auto;

          &::-webkit-scrollbar {
            width: 8px;
            display: initial;
          }
        `
      : css`
          padding-inline: 18px;
          padding-block: 16px;
        `}
`
